import React from 'react'
import styles from '../SurfacesSearch/surfaces-search.module.css'
import { findSecondarySurface } from '../../../utils/design'
import MaterialSearch from './material-search'
import MaterialGroup from './material-group'

class SurfacesSearchSecondary extends React.Component {
  constructor(props) {
    super(props)
    this.materialsearchElement = React.createRef()
    let activeOption = ''
    if(this.props.activePanel?.panelOptions?.length > 0){
      activeOption = this.props.activePanel.panelOptions[0].id
      if(this.props.activePanel?.panelOption){
        activeOption = this.props.activePanel.panelOption
      }
    }
    console.log(activeOption)
    this.state = {
      materials: this.props.data.allMaterialSecondary.nodes,
      showMaterialSearch: false,
      activeMaterialGroup: {},
      activeMaterial: {},
      activeOption: activeOption
    }
  }

  componentDidMount() {
    this.setState({
      showMaterialSearch: this.props.showMaterialSearch
    })
  }

  onClickItem = (materialGroup, subMaterial) => {
    this.setState({
      activeMaterialGroup: materialGroup,
      activeMaterial: subMaterial,
      showMaterialSearch: true
    })
  }

  onClickOption = (optionId) => {
    this.setState({
      activeOption: optionId
    })
  }

  onCloseButton = () => {
    this.setState({
      showMaterialSearch: false
    })
  }

  applySurface = (surface, lightplane=false, ceramiloc=false) => {
    const { applySurface } = this.props
    surface.material = {
      id: this.state.activeMaterialGroup.id,
      nid: this.state.activeMaterialGroup.nid,
      name: this.state.activeMaterialGroup.name
    }
    if(this.state.activeMaterialGroup.materialType){
      surface.materialGroup = {
        id: this.state.activeMaterialGroup.materialType.id,
        nid: this.state.activeMaterialGroup.materialType.nid,
        name: this.state.activeMaterialGroup.materialType.name
      }  
    }
    applySurface(surface, lightplane, ceramiloc, '', this.state.activeOption)
  }

  onPatternSelect = (pattern) => {
    var surface = findSecondarySurface(pattern.id, this.props.data.allMaterialSecondary.nodes)
    // var surface = this.findSurface(pattern.nid)
    if(surface){
      this.setState({
        activeMaterialGroup: surface.materialGroup,
        activeMaterial: surface.material,
        showMaterialSearch: true,
      },function(){
        this.materialsearchElement.current.onMaterialSelect(surface.material)
        this.materialsearchElement.current.onFinishSelect(surface)
      }.bind(this))
    }
  }

  findSurface = (surfaceId) => {
    var foundSurface = null
    this.state.materials.forEach(function(material){
      material.surfaces.forEach(function(finish, index){
        if(surfaceId === finish._id){
          foundSurface = finish
          foundSurface.material = material
        }
      })
    })
    return foundSurface
  }

  render(){
    let activeOption = this.state.activeOption
    if(!activeOption && this.props.activePanel?.panelOptions?.length){
      activeOption = this.props.activePanel.panelOptions[0].id
    }
    let items = this.state.materials.map(function(item, key){
      if(item.disabled){
        return null
      }
      return (
        <MaterialGroup
          material={item}
          key={item.id}
          onClick={this.onClickItem}
        />
      )
    }.bind(this))
    let searchPanelContent = <div className={styles['search__panel']}>{items}</div>
    if(this.state.showMaterialSearch){
      searchPanelContent = <MaterialSearch
      ref={this.materialsearchElement}
      configuration={this.props.configuration}
      materialGroup={this.state.activeMaterialGroup}
      material={this.state.activeMaterial}
      onCloseButton={this.onCloseButton}
      applySurface={this.applySurface}
      activePanel={this.props.activePanel}
      activeOption={activeOption}
      onClickOption={this.onClickOption}
      />
    }

    return (
      <div>
        <div className={styles['search__tab__singular']}>
        <div className={styles['title__mixed']}>
        Surfaces
        </div>
        <div className={styles['small__title']}>
        {this.props.shortName}
        </div>
        </div>
        {searchPanelContent}
      </div>
    )
  }
}

export default SurfacesSearchSecondary
