import React from 'react'
import { graphql } from 'gatsby'
import Design from '../components/Design'
import PrivateRoute from '../components/PrivateRoute'

const App = ({data}) => {
  return(
    <>
    <PrivateRoute path="/design/:designid" component={Design} fsOnly='false' data={data} watermark={true} />
    <PrivateRoute path="/design/:designid/watermark" component={Design} fsOnly='false' data={data} watermark={true} />
    <PrivateRoute path="/design/:designid/no-watermark" component={Design} fsOnly='false' data={data} watermark={false} />
    </>
  )
}

export default App

export const query = graphql`
query DesignQuery {
  allMaterialType(sort: {fields: sort, order: ASC}) {
    nodes {
      _id
      nid
      name
      disabled
      hideSearchRow
      materials {
        _id
        nid
        name
        materialType
        disabled
        sort
        materialSwatch {
          publicURL
        }
        note {
          message
        }
        subMaterialLabel
        subMaterials {
          _id
          nid
          name
          sort
          hidden
          disabled
          hideLabel
          hideSearchRow
          subMaterialSwatch {
            publicURL
          }
          surfaceLabel
          surfaces {
            _id
            id
            nid
            name
            fullName
            glass
            lightplaneCompatible
            lightplaneOpacity
            ceramilocCompatible
            captureOnly
            whiteLight
            disabled
            dontRotate
            isCustomSurface
            finish {
              _id
              id
              nid
              name
            }
            pattern {
              _id
              nid
              name
            }
            surfaceSwatch {
              publicURL
            }
            surfacePreview {
              publicURL
            }
            surfaceFull {
              publicURL
            }
          }
        }
      }
    }
  }
  allMaterialSecondary {
    nodes {
      _id
      id
      nid
      name
      disabled
      surfacesLabel
      materialSwatch {
        publicURL
      }
      materialType {
        nid
        id
        name
        _id
      }
      surfaces {
        _id
        id
        nid
        name
        fullName
        finish {
          _id
          id
          nid
          name
          hideLabel
        }
        surfaceSwatch {
          publicURL
        }
        surfacePreview {
          publicURL
        }
        surfaceFull {
          publicURL
        }
        disabled
        hidden
        ceramilocCompatible
      }
    }
  }
  allConfigurationFamily(sort: {fields: sort, order: ASC}) {
    nodes {
      _id
      name
      sort
      thumbnail
      description
      thumbnailImage {
        publicURL
      }
      configurations {
        id {
          id
        }
      }
      presets {
        id {
          id
        }
      }
    }
  }
  allConfiguration(sort: {fields: sort, order: ASC}) {
     nodes {
       _id
       nid
       id
       name
       description
       configThumbnailImage {
         publicURL
       }
       ceilingLegends {
         _id
         label
         left
         top
       }
       primaryPanels {
         _id
         id
         label
         materialsAllowed
         materialsExcluded
         materialsNote
         name
         nid
         patternsExcluded
         dualOpenHide
         panelCoords {
           _id
           id
           name
           nid
           dualOpenHide
           dualOpenName
           disableLightPlaneHandrails
           fdls {
             _id
             name
             rotateSurface
             x1
             x3
             x2
             x4
             y1
             y2
             y3
             y4
           }
           legend {
             _id
             label
             dualOpenLabel
             hideLabel
             left
             top
           }
           lsbdrs {
             _id
             name
             rotateSurface
             x1
             x2
             x3
             x4
             y1
             y2
             y3
             y4
           }
           lsbwrs {
             _id
             name
             rotateSurface
             x1
             x2
             x3
             x4
             y1
             y2
             y3
             y4
           }
         }
       }
       secondaryPanels {
         _id
         id
         label
         name
         nid
         shortName
         dualOpenHide
         panelCoords {
           _id
           id
           name
           nid
           dualOpenHide
           dualOpenName
           fdls {
             _id
             name
             rotateSurface
             x1
             x2
             x3
             x4
             y1
             y2
             y3
             y4
           }
           legend {
             _id
             label
             dualOpenLabel
             hideLabel
             left
             top
           }
           lsbdrs {
             _id
             name
             rotateSurface
             x1
             x2
             x3
             x4
             y1
             y2
             y3
             y4
           }
           lsbwrs {
             _id
             name
             rotateSurface
             x1
             x2
             x3
             x4
             y1
             y2
             y3
             y4
           }
         }
         panelOptions {
          id
          name
          shortName
          image {
            publicURL
          }
          lsbwrs {
            publicURL
          }
          swatch {
            publicURL
          }
          preview {
            publicURL
          }
          alt {
            image {
              publicURL
            }
            lsbwrs {
              publicURL
            }
          }
         }
       }
       crashRailLegends {
         _id
         label
         left
         top
       }
       framePanels {
         _id
         id
         label
         name
         nid
         shortName
         showFrameStyle
         renderBehindPanels
         dualOpenHide
         legends {
           _id
           label
           dualOpenHide
           dualOpenLabel
           left
           name
           top
         }
         options {
           _id
           color
           id
           nid
           name
           material
           fullName
           fdlsImage {
             publicURL
           }
           frameImageImage {
             publicURL
           }
           lsbwrsImage {
             publicURL
           }
           previewImage {
             publicURL
           }
           swatchImage {
             publicURL
           }
           alt {
              frameImageImage {
                publicURL
              }
              lsbwrsImage {
                publicURL
              }
              fdlsImage {
                publicURL
              }
              swatchImage {
                publicURL
              }
              previewImage {
                publicURL
              }
           }
         }
       }
       framePlaceholders {
         fdlsImage {
           publicURL
         }
         lsbdrsImage {
           publicURL
         }
         lsbwrsImage {
           publicURL
         }
       }
       handrailLegends {
         _id
         label
         left
         top
       }
       addTechnicalDrawings
       hideLightPlaneDialog
       hidePanelFramesDialog
       hidefdls
       hidelsbwrs
       favoriteSurfaces {
         _id
         name
         surfaces {
           _id
           id
         }
       }
       handrailsAllowed {
        seriesId
        locationsAllowed
       }
       dimensionLimits {
         shellHeight {
           min
           max
         }
         ceilingHeight {
           min
           max
         }
         width {
           min
           max
         }
         depth {
           min
           max
         }
       }
     }
   }
   allDesignPreset {
     nodes {
       name
       _id
       configuration {
         id
         nid
       }
       ceiling {
         finish {
           id
           nid
         }
         material {
           id
           nid
         }
       }
       crashrail
       crashrailLocation
       floor {
         nid
         id
       }
       framePanels {
         nid
         id
         surface {
           id
           nid
         }
       }
       handrail {
         nid
         id
         selectedMaterial {
           id
           nid
         }
         selectedStandoff {
           nid
           id
           selectedMaterial {
             id
             nid
           }
         }
       }
       handrailLocation
       lightTemperature
       lighting {
         id
         nid
       }
       panelFrameType
       primaryPanels {
         nid
         id
         panelCoords {
           ceramiloc
           id
           lightplane
           nid
           surface {
             id
             nid
           }
         }
       }
       secondaryPanels {
         id
         nid
         panelCoords {
           ceramiloc
           id
           lightplane
           nid
           surface {
             nid
             id
           }
         }
       }
       presetImageFile {
         publicURL
       }
       additionalDetails {
        ceilingHeight
        depth
        openingOption
        quantity
        shellHeight
        width
      }
     }
   }
}
`
